.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
.form-layout-1 select {
  font-weight: 400;
}
.form-layout-1 .form-control {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.form-layout-1 .form-control:focus {
  border-color: #999999 !important;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) !important;
}
.form-layout-1 .radio input:checked + i:after,
.form-layout-1 .checkbox input:checked + i:after,
.form-layout-1 .radio input:checked ~ i:after,
.form-layout-1 .checkbox input:checked ~ i:after {
  opacity: 1 !important;
}
.form-layout-1 .title {
  color: #324c80;
  margin-bottom: 30px;
}
.form-layout-1 .form-control {
  border-radius: 0;
  height: 40px;
  font-size: 20px;
  color: #4d4d4d;
}
.form-layout-1 .form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999999;
}
.form-layout-1 .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
  opacity: 1;
}
.form-layout-1 .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
  opacity: 1;
}
.form-layout-1 .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
}
.form-layout-1 .warningLabel,
.form-layout-1 .note-error {
  color: #BA1D23;
  padding: 0 0 0 2px;
}
.form-layout-1 .state-error input.form-control,
.form-layout-1 .state-error select,
.form-layout-1 .state-error:focus {
  border-color: #ccc;
  background: inherit !important;
}
.form-layout-1 .label {
  color: #324c80;
  font-weight: 400;
  font-size: 20px;
  padding: 4px 7px 4px 0;
}
.form-layout-1 .input-group-addon {
  background: #b3c0c9;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.form-layout-1 .form-group {
  margin-bottom: 20px;
}
.form-layout-1 .form-control[disabled] {
  background: #fff;
}
.form-layout-1 .state-disabled,
.form-layout-1 .state-disabled:hover {
  cursor: not-allowed;
  color: #555555 !important;
  opacity: 1!important;
}
.form-layout-1 .state-disabled i,
.form-layout-1 .state-disabled:hover i {
  border-color: #333 !important;
}
.form-layout-1 .containsMultiSelect > .row {
  margin-bottom: 5px;
}
.form-layout-1 .containsMultiSelect select,
.form-layout-1 .containsMultiSelect .btn-default {
  background: #b3c0c9;
  width: 100%;
  display: block;
  color: #fff;
  text-align: center;
}
.form-layout-1 .containsMultiSelect .state-error select {
  background: #b3c0c9;
}
.form-layout-1 label {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.form-layout-1 .iconSet .btn-icon {
  background: none;
  font-size: 14px;
  vertical-align: super;
  border: none;
  color: #868686;
}
.form-layout-1 .iconSet .btn-icon:hover {
  color: #fac700;
}
.form-layout-1 .knowledge-error .knowledgeNameLabel {
  font-style: italic;
  color: #BA1D23;
}
.datepicker table tr td span.active.active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active {
  background: #b3c0c9 !important;
  color: #fff;
}
