.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
.footer-v1 .footer {
  padding: 40px 0 0;
}
.footer-v1 .footer ul {
  line-height: 2;
}
footer.footer,
footer + .copyright {
  color: #888888;
}
footer.footer a,
footer + .copyright a,
footer.footer a:hover,
footer + .copyright a:hover,
footer.footer a:visited,
footer + .copyright a:visited {
  color: #888888;
}
#followButtons {
  font-size: 19px;
}
footer {
  font-weight: 300;
  font-size: 15px;
}
footer .h5 {
  margin-bottom: 15px;
  display: block;
}
footer .input-group-btn {
  padding-left: 8px;
}
footer .input-group-btn .btn-yellow {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #545454 !important;
}
footer .input-group-btn .btn-yellow:hover {
  color: #ffffff !important;
}
footer .footerMenu .footerSubMenu > li {
  margin-left: 2.3rem;
}
@media (min-width: 768px) {
  footer .footerMenu .footerSubMenu > li {
    margin-left: 0;
  }
}
.footer-v1 .copyright {
  background: #000;
}
.footer-v1 .copyright #rightFooter {
  margin-left: -10px;
}
.footer-v1 .copyright a {
  padding: 0 9px;
}
.footer-v1 .copyright a,
.footer-v1 .copyright a:visited,
.footer-v1 .copyright a:hover,
.footer-v1 .copyright a:focus {
  color: #e7edf1;
}
.input-group.visible-xs {
  display: table !important;
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  footer .adressPanel {
    padding: 0 5px 0 15px;
    margin-top: 0;
    float: right;
  }
  footer .input-group.visible-xs {
    display: none !important;
  }
  footer .footer-v1 .footer ul {
    line-height: 1.5;
  }
  footer .container .row > div {
    margin-bottom: 15px;
  }
}
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  #followButtons {
    font-size: 23px;
  }
  #followButtons a:hover {
    color: #fff;
    text-decoration: none;
  }
}
