.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
.blue-multiselect button.multiselect {
  border: 0;
  background-color: #195184;
  color: #fff;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
}
.blue-multiselect button.multiselect .caret {
  color: #fac700;
}
.blue-multiselect .dropdown-menu {
  right: 0;
  background: #195184;
  border: 0;
}
.blue-multiselect .dropdown-menu a,
.blue-multiselect .dropdown-menu label {
  color: #fff;
}
.blue-multiselect .dropdown-menu a:hover,
.blue-multiselect .dropdown-menu a:focus {
  background: #002446;
}
.blue-multiselect .dropdown-menu > li > a label {
  padding-left: 30px;
}
.blue-multiselect .dropdown-menu > li > a label i {
  border-color: #FFF;
}
.light-blue-multiselect button.multiselect {
  border-radius: 3px;
  border: 0;
  background-color: #b3c0c9;
  color: #fff;
  box-shadow: none !important;
  position: relative;
}
.light-blue-multiselect button.multiselect .caret {
  color: #fff;
}
.light-blue-multiselect .dropdown-menu {
  right: 0;
  background: #b3c0c9;
  border: 0;
}
.light-blue-multiselect .dropdown-menu a,
.light-blue-multiselect .dropdown-menu label {
  color: #fff;
}
.light-blue-multiselect .dropdown-menu a:hover,
.light-blue-multiselect .dropdown-menu a:focus {
  background-color: #95a7b4;
}
.light-blue-multiselect .dropdown-menu > .active > a {
  background-color: #95a7b4;
}
.light-blue-multiselect .dropdown-menu > .active > a:hover {
  background-color: #869ba9;
}
.light-blue-multiselect .dropdown-menu > li > a label {
  padding-left: 30px;
}
.light-blue-multiselect .dropdown-menu > li > a label i {
  border-color: #FFF;
}
.white-multiselect {
  position: relative;
}
.white-multiselect button.form-control {
  border-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #195184;
}
.white-multiselect button.form-control .caret {
  color: #fac700;
  padding-top: 5px;
}
.white-multiselect.open button.form-control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.white-multiselect .dropdown-menu {
  right: 0;
  background: #fff;
  border: 0;
}
.white-multiselect .dropdown-menu a,
.white-multiselect .dropdown-menu label {
  color: #195184;
}
.white-multiselect .dropdown-menu a:hover,
.white-multiselect .dropdown-menu a:focus {
  background-color: #eff1f3;
}
.white-multiselect .dropdown-menu > .active > a {
  background-color: #eff1f3;
}
.white-multiselect .dropdown-menu > .active > a:hover {
  background-color: #e0e5e9;
}
.white-multiselect .dropdown-menu > li > a label {
  padding-left: 30px;
}
.white-multiselect .dropdown-menu > li > a label i {
  border-color: #FFF;
}
.darkest-blue-multiselect button.multiselect {
  border: 0;
  background-color: #002446;
  color: #fff;
  box-shadow: none !important;
  position: relative;
}
.darkest-blue-multiselect button.multiselect .caret {
  color: #fac700;
}
.darkest-blue-multiselect .dropdown-menu {
  right: 0;
  background: #002446;
  border: 0;
}
.darkest-blue-multiselect .dropdown-menu a,
.darkest-blue-multiselect .dropdown-menu label {
  color: #fff;
}
.darkest-blue-multiselect .dropdown-menu a:hover,
.darkest-blue-multiselect .dropdown-menu a:focus {
  background: #195184;
}
.darkest-blue-multiselect .dropdown-menu > li > a label {
  padding-left: 30px;
}
.darkest-blue-multiselect .dropdown-menu > li > a label i {
  border-color: #FFF;
}
.multiselect-container.dropdown-menu label {
  width: 100% !important;
}
ul.multiselect-container li.active i.multi-checkbox:after {
  opacity: 1;
}
ul.multiselect-container li i.multi-checkbox:after {
  opacity: 0;
}
ul.multiselect-container a.multi-select-link:hover i.multi-checkbox,
ul.multiselect-container a.multi-select-link:focus i.multi-checkbox {
  border-color: #000;
}
ul.multiselect-container a.multi-select-link i.multi-checkbox {
  position: absolute;
  width: 17px;
  height: 17px;
  border: 2px solid #FFF;
  margin-top: 7px;
}
ul.multiselect-container a.multi-select-link i.multi-checkbox:after {
  content: '\f00c';
  position: absolute;
  top: -1px;
  left: 1px;
  font: normal 12px/16px FontAwesome;
}
