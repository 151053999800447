@import '../_variables';

.mixin-teaser-img (@teaser-image) {
  .teaser-img {
    background: url(@teaser-image) center center no-repeat;
    min-height: @poster-height;
    overflow: hidden;
    background-size: cover;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1921px) {
  .teaser-img {
    min-height: @poster-height+200px !important;
  }
}
