@import "../_variables.less";

/* Navbar */
.navbar {
  margin-bottom: 0;
  border: 0px;
  background-color:#fff;
}

.header {
  border-bottom:0;
  .navbar-nav {
    padding-top: 1rem;
    & > li > a {
      padding: 0.7em 1em 0.45em;
    }
  }

  .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    border-bottom-color: @petag-green;
  }

  //remove carret
  .navbar-nav>.dropdown > a:after {
    content: "";
  }


  li.dropdown:last-child {
    > .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}

.navbar .navbar-header > .nav-ism-logo {
  margin-left: 12px;
  padding: 10px 0 0 0;
  img {
    max-width: 190px;
  }
}

.header .dropdown-menu {
  border-top:0;
  margin-top:-1px !important;
  padding-top:1px;
}

.header .navbar-toggle {
  margin: 6px 15px 8px 0;
}

/*topbar*/
.header {
  .topbar {
    padding:0;
    a {
      cursor: pointer;
    }
    .switch {
      padding: 8px;
      background: #fff;

      span {
        color:lighten(@footer-color,20%) !important;
      }

      span.activeSelection {
        color: black !important;
      }
    }
  }
}

.navbar.navbar-default.mega-menu {
  border:0;
}

.header .navbar-brand {
  margin-top: -20px;
}


.navbar-default .navbar-nav > .active > a  {
}

.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom:4px solid @petag-green;
  box-sizing: border-box;
}
.header .navbar-nav > li > a {
  border-bottom:4px solid white;
  bottom: 0 !important;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-bottom-color: @petag-green;
}

.nav .dropdown:hover > a {
  border-bottom:4px solid @petag-green;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background: #fff;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background:#fff;
}

.header .navbar-nav > .active > a:hover {
  color:#777777;
}
.header .navbar-nav > .active > a:focus {
  text-decoration:underline;
}

.header .navbar-nav .dropdown-toggle .caret {
  margin-top: 10px;
}

#___gcse_0 > div > form > table > tbody > tr > td.gsc-search-button > button, #___gcse_1 > div > div > form > table > tbody > tr > td.gsc-search-button > button {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  background-color: #76b631 !important;
  border-color: #76b631 !important;
  &.btn {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &:hover {
    color: #868686 !important;
  }
}

#gs_cb50{
  top: 5px;
  position: relative;
}


@media only screen and (max-width : 991px) {
  .navbar {
    padding-bottom: 10px;
    border-bottom: 1px solid @blue-gray;
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .navbar .navbar-header > .nav-ism-logo {
    margin-left: 0;
    img {
      max-width: 200px;
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .navbar .navbar-header > .nav-ism-logo {
    margin-left: 0;
    img {
      max-width: 175px;
    }
  }

  .header .navbar-nav > li > a {
    padding: 9px 15px 9px 15px;
  }

  .header .navbar-nav > li  > a {
    padding-top:45px !important;
    padding-bottom: 35px !important;

  }

  .header .navbar-brand {
    padding: 10px 0 !important;
    margin-top: 16px;
  }

  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    background:#fff;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .header .navbar-nav > li  > a {

    font-size: 19px;

  }

}