@import "../_variables";

.square-box {
  position: relative;
  width: @square-box-size + @square-box-padding;
  height: @square-box-size + @square-box-padding;
  overflow: hidden;
  padding: @square-box-padding;
}
.square-box:before {
  content:"";
  display: block;
  padding-top: 80%;
}
.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;

}
.square-content div {
  display: table;
  width: 100%;
  height: 100%;
}

.square-content div.row {
  display: table-row;
}

.square-content span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-color: white
}
.square-content span img {
  max-width: @square-box-size;
  max-height: @square-box-size;
}

.company-box {
  position: relative;
  width: @box-width + @square-box-padding;
  height: @box-height + @square-box-padding;
  overflow: hidden;
  margin-bottom: 11px;

}
.company-box:before {
  content:"";
  display: block;
  padding-top: 80%;
}
.company-box-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;

  div {
    display: table;
    width: 100%;
    height: 100%;
  }
  span {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-color: white
  }
  span img {
    max-width: @box-width;
    max-height: @box-height;
  }
}