
@import "../_variables.less";
// Footer

.footer-v1 .footer {
  padding: 40px 0 0;

  ul {
    line-height: 2;
  }
}


footer.footer, footer+.copyright{
  color: @footer-text;

  a, a:hover, a:visited {
    color: @footer-text;
  }
}

#followButtons {
  font-size: 19px;
}

footer {
  font-weight: @slim;
  font-size: 15px;

  .h5 {
    margin-bottom:15px;
    display: block;
  }

  .input-group-btn {
    padding-left:8px;


    .btn-yellow {
      -webkit-border-radius: 3px !important;
      -moz-border-radius: 3px !important;
      border-radius: 3px !important;
      padding-top: 4px;
      padding-bottom: 4px;
      color: #545454 !important;
    }

    .btn-yellow:hover {
      color: #ffffff !important;
    }
  }

  .footerMenu .footerSubMenu > li {
    margin-left: 2.3rem;
    @media (min-width: 768px) {
      margin-left: 0;
    }
  }
}
.footer-v1 .copyright {
  background: #000;

  #rightFooter {
    margin-left: -10px;
  }

  a {
    padding:0 9px;
  }
  a,
  a:visited,
  a:hover,
  a:focus {
    //font-weight: bold;
    color: @light-blue;
  }
}
.input-group.visible-xs {
  display: table !important;
}





/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  footer {
    .adressPanel {
      padding: 0 5px 0 15px;
      margin-top: 0;
      float: right;
    }

    .input-group.visible-xs {
      display: none !important;
    }

    .footer-v1 .footer {
    ul {
      line-height: 1.5;
    }
  }
  .container .row > div {
    margin-bottom: 15px;
  }
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  #followButtons {
    font-size: 23px;
    a:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}
