.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
.btn-light-blue {
  background-color: #b3c0c9;
  color: #fff !important;
}
.uploadButton {
  background-color: #b3c0c9 !important;
  color: #fff !important !important;
}
.btn {
  padding-top: 4px;
  padding-bottom: 4px;
}
.btn-u {
  border-radius: 3px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 18px;
}
.btn,
input[type="submit"] {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn.blue-gray {
  background: #b2c0c9;
  color: #fff;
  border-color: #b2c0c9;
}
.btn.blue-gray:hover {
  background: #a3b4be;
}
.btn.blue-gray:focus {
  background: #acbbc5;
}
.btn-yellow:focus {
  outline: none !important;
}
.btn-yellow:hover {
  background: #ffcc05 !important;
  color: #545454 !important;
}
.btn.closeOpen {
  padding-top: 6px;
  padding-bottom: 7px;
}
.btn.btn-error {
  background: #BA1D23;
  color: #fff;
  border-color: #BA1D23;
}
