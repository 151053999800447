/* 
* Version: 1.0
* Dark Gray Color: @darker-text-gray;
* Gray Hover Color: @text-gray;
* Additional color: #cad9f6;
* rgba(71, 101, 160, 1);
*/

a {
  color: @darker-text-gray;
}
a:focus, 
a:hover, 
a:active {
	color: @darker-text-gray;
}
.color-green {
	color: @darker-text-gray;
}
a.read-more:hover { 
	color: @darker-text-gray;
}
.linked:hover {
	color: @darker-text-gray;
}

/* Headers Default
------------------------------------*/
.header .navbar-nav > .active > a {
  color: @darker-text-gray;
}
.header .navbar-nav > .active > a {
  border-color: @darker-text-gray;
}
.header .dropdown-menu {
	border-top: solid 2px @darker-text-gray;
}
.header .navbar-nav > li:hover > a {
  color: @text-gray;
}
.header .nav > li > .search:hover {
  border-bottom-color: @text-gray;
  color: @text-gray;
}
.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom-color: @petag-green;
}
.header .navbar-toggle {
  border-color: @text-gray;
}
.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: @darker-text-gray;
}
.header .navbar-toggle:hover {
  background: @text-gray !important;
}
.header .navbar-nav > .open > a,
.header .navbar-nav > .open > a:hover,
.header .navbar-nav > .open > a:focus {
  color: @darker-text-gray;
}

/* Design for max-width: 991px */
@media (max-width: 991px) {
	.header .navbar-nav > .active > a,
	.header .navbar-nav > .active > a:hover,
	.header .navbar-nav > .active > a:focus {
	  background: @darker-text-gray;
	  color: #fff !important;
	}
	.header .navbar-nav > .active > a {
	  border-color: #eee;
	}
	.header .nav > li > .search:hover {
	  background: @darker-text-gray;
	}
}

/* Headers v1
------------------------------------*/
.header-v1 .navbar-default .navbar-nav > .active > a,
.header-v1 .navbar-default .navbar-nav > li > a:hover,
.header-v1 .navbar-default .navbar-nav > li > a:focus {
  color: @darker-text-gray;
}
.header-v1 .dropdown-menu {
  border-color: @darker-text-gray
}
.header-v1 .navbar-default .navbar-nav > li:hover > a {
  color: @darker-text-gray;
}
.header-v1 .navbar .nav > li > .search:hover {
  color: @darker-text-gray;
}
.header-v1 .navbar .nav > li > .search:hover {
  color: @darker-text-gray;
}
.header-v1 .navbar-default .navbar-toggle {
  border-color: @text-gray;
}
.header-v1 .navbar-toggle,
.header-v1 .navbar-default .navbar-toggle:hover,
.header-v1 .navbar-default .navbar-toggle:focus {
  background: @darker-text-gray;
}
.header-v1 .navbar-toggle:hover {
  background: @text-gray !important;
}

/* Design for max-width: 991px */
@media (max-width: 991px) {
	.header-v1 .navbar-default .navbar-nav > li:hover > a {
  	border-color:  #eee;
	}
	.header-v1 .navbar-default .navbar-nav > .active > a,
	.header-v1 .navbar-default .navbar-nav > .active > a:hover,
	.header-v1 .navbar-default .navbar-nav > .active > a:focus {
	  background-color: @darker-text-gray;
	}
	.header-v1 .navbar-default .navbar-nav > .active > a {
	  border-color: #eee;
	}
	.header-v1 .navbar .nav > li > .search:hover {
	  background-color: @darker-text-gray;
	}
}

/* Headers v2
------------------------------------*/
.header-v2 .dropdown-menu {
  border-color: @darker-text-gray;
}
.header-v2 .navbar-default .navbar-toggle {
  border-color: @text-gray;
}
.header-v2 .navbar-toggle,
.header-v2 .navbar-default .navbar-toggle:hover,
.header-v2 .navbar-default .navbar-toggle:focus {
  background: @darker-text-gray;
}
.header-v2 .navbar-toggle:hover {
  background: @text-gray !important;
}

/* Design for max-width: 991px */
@media (max-width: 991px) {
	.header-v2 .navbar-default .navbar-nav > .active > a,
	.header-v2 .navbar-default .navbar-nav > .active > a:hover,
	.header-v2 .navbar-default .navbar-nav > .active > a:focus {
	  background: @darker-text-gray;
	  color: #fff !important;
	}
	.header-v2 .navbar-default .navbar-nav > li > a:hover {
   	color: @darker-text-gray;
	}
}	

/* Headers v3
------------------------------------*/
.header-v3 .navbar-default .navbar-nav > .active > a {
  color: @darker-text-gray;
}
.header-v3 .navbar-default .navbar-nav > li:hover > a {
  color: @text-gray;
}
.header-v3 .dropdown-menu {
  border-color: @darker-text-gray;
}
.header-v3 .navbar-default .navbar-toggle {
  border-color: @text-gray;
}
.header-v3 .navbar-toggle,
.header-v3 .navbar-default .navbar-toggle:hover,
.header-v3 .navbar-default .navbar-toggle:focus {
  background: @darker-text-gray;
}
.header-v3 .navbar-toggle:hover {
  background: @text-gray !important;
}
.header-v3 .navbar .nav > li > .search:hover {
  background: inherit;
  color: @darker-text-gray;
}

/* Design for max-width: 991px */
@media (max-width: 991px) {

	.header-v3 .navbar-default .navbar-nav > .active > a,
	.header-v3 .navbar-default .navbar-nav > .active > a:hover,
	.header-v3 .navbar-default .navbar-nav > .active > a:focus {
	  background: @darker-text-gray;
	  color: #fff !important;
	}
	.header-v3 .navbar-default .navbar-nav > .active > a {
	  border-color: #eee;
	}
	.header-v3 .navbar .nav > li > .search:hover {
	  background: @darker-text-gray;
	}
}	

/* Headers v4
------------------------------------*/
.header-v4 .navbar-default .navbar-nav > li > a:hover, 
.header-v4 .navbar-default .navbar-nav > .active > a {
  border-color: @darker-text-gray;
}
.header-v4 .navbar-default .navbar-nav > .active > a {
  color: @darker-text-gray;
}
.header-v4 .navbar-default .navbar-nav > li:hover > a {
  border-color: @darker-text-gray;
  color: @text-gray;
}
.header-v4 .navbar .nav > li > .search:hover {
  color: @darker-text-gray;
}
.header-v4 .navbar-default .navbar-nav > .open > a,
.header-v4 .navbar-default .navbar-nav > .open > a:hover,
.header-v4 .navbar-default .navbar-nav > .open > a:focus {
  color: @darker-text-gray;
}

/* Design for max-width: 991px */
@media (max-width: 991px) {
	.header-v4 .navbar-default .navbar-nav > li:hover > a {
  border-color:  #eee;
	}
	.header-v4 .navbar-default .navbar-nav > .active > a,
	.header-v4 .navbar-default .navbar-nav > .active > a:hover,
	.header-v4 .navbar-default .navbar-nav > .active > a:focus {
	  color: @darker-text-gray !important;
	}
	.header-v4 .navbar-default .navbar-nav > .active > a {
	  border-color: #eee;
	}
	.header-v4 .navbar .nav > li > .search:hover {
	  background: @darker-text-gray;
	}
}

/* Headers v5
------------------------------------*/
.header-v5 .navbar-default .navbar-nav > li > a:hover,
.header-v5 .navbar-default .navbar-nav > .active > a {
  border-top: 2px solid @darker-text-gray;
}
.header-v5 .navbar-default .navbar-nav > .active > a {
  color: @darker-text-gray;
}
.header-v5 .navbar-default .navbar-nav > li:hover > a {
  color: @darker-text-gray;
}
.header-v5 .navbar-default .navbar-nav > .open > a,
.header-v5 .navbar-default .navbar-nav > .open > a:hover,
.header-v5 .navbar-default .navbar-nav > .open > a:focus {
  color: @darker-text-gray;
}
.header-v5 .dropdown-menu li > a:hover {
  background: @darker-text-gray;
}
.header-v5 .dropdown-menu .active > a,
.header-v5 .dropdown-menu li > a:hover {
  background: @darker-text-gray;
}
.header-v5 .dropdown-menu {
  border-color: @darker-text-gray;
}
.header-v5 .dropdown-menu li.dropdown-submenu:hover > a {
  background: @darker-text-gray;
}
.header-v5 .dropdown-menu .style-list li > a:hover {
  background: none;
}
.header-v5 .style-list li a:hover {
  color: @darker-text-gray;
}

/* Shopping cart
------------------------------------*/
.header-v5 .shop-badge.badge-icons i {
  color: @darker-text-gray;
}
.header-v5 .shop-badge span.badge-sea {
  background: @darker-text-gray;
}
.header-v5 .badge-open {
  border-top: 2px solid @darker-text-gray;
  box-shadow: 0 5px 5px 0 rgba(71, 101, 160, 0.075);
}

/* Header v6
------------------------------------*/
/* Search */
.header-v6 .shopping-cart .shopping-cart-open {
	border-top-color: @darker-text-gray !important;
}
.header-v6 li.menu-icons span.badge {
	background: @darker-text-gray;
}
/* Dropdown Menu */
.header-v6 .dropdown-menu {
	border-top-color: @darker-text-gray;
}

/* Media Queries */
@media (max-width: 991px) {
	/* Navbar Nav */
	.header-v6 .navbar-nav > .active > a,
	.header-v6 .navbar-nav > .active > a:hover,
	.header-v6 .navbar-nav > .active > a:focus {
		color: @darker-text-gray !important;
	}
	.header-v6 .nav .open > a,
	.header-v6 .nav .open > a:hover,
	.header-v6 .nav .open > a:focus {
	  border-color: #eee;
	}
	.header-v6 .navbar-nav > li > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > li > a:focus,
	.header-v6 .navbar-nav .open .dropdown-menu > .active > a,
	.header-v6 .navbar-nav .open .dropdown-menu > .active > a:hover,
	.header-v6 .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: @darker-text-gray !important;
	}
	.header-v6 .mega-menu .equal-height-list li a:hover {
		color: @darker-text-gray !important;
	}

	/* Classic Dark */
	.header-v6 .mega-menu .equal-height-list li a:hover {
		color: @darker-text-gray;
	}

	/* Dark Responsive Navbar */
	.header-v6.header-dark-res-nav .navbar-nav > li a:hover,
	.header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a:hover {
		color: @darker-text-gray;
	}
	.header-v6.header-dark-res-nav .nav .open > a,
	.header-v6.header-dark-res-nav .nav .open > a:hover,
	.header-v6.header-dark-res-nav .nav .open > a:focus {
	  border-color: #555;
	}
}

@media (min-width: 992px) {
	/* Default Style */
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
		color: @darker-text-gray !important;
	}
	.header-v6 .dropdown-menu .active > a,
	.header-v6 .dropdown-menu li > a:hover,
	.header-fixed .header-v6.header-fixed-shrink .dropdown-menu .active > a,
	.header-fixed .header-v6.header-fixed-shrink .dropdown-menu li > a:hover {
		color: @darker-text-gray !important;
	}
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
		color: @darker-text-gray;
	}

	/* Classic White */
	.header-fixed .header-v6.header-classic-white .navbar-nav .active > a,
	.header-fixed .header-v6.header-classic-white .navbar-nav li > a:hover {
		color: @darker-text-gray;
	}

	/* Classic Dark */
	.header-v6.header-classic-dark .navbar-nav .active > a,
	.header-v6.header-classic-dark .navbar-nav li > a:hover,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav li > a:hover {
		color: @darker-text-gray;
	}
	.header-v6.header-classic-dark .dropdown-menu .active > a,
	.header-v6.header-classic-dark .dropdown-menu li > a:hover {
		color: @darker-text-gray !important;
	}

	/* Dark Dropdown */
	.header-v6.header-dark-dropdown .dropdown-menu .active > a,
	.header-v6.header-dark-dropdown .dropdown-menu li > a:hover {
		color: @darker-text-gray;
	}

	/* Dark Scroll */
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav .active > a,
	.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav li > a:hover {
		color: @darker-text-gray;
	}
}

/* Header v7
------------------------------------*/
.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li.active > a {
	color: @darker-text-gray !important;
}
.header-v7 .dropdown-menu .active > a,
.header-v7 .dropdown-menu li > a:focus,
.header-v7 .dropdown-menu li > a:hover {
	color: @darker-text-gray !important;
}
.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li > a:focus,
.header-v7 .navbar-default .navbar-nav > .active > a,
.header-v7 .navbar-default .navbar-nav > .active > a:hover,
.header-v7 .navbar-default .navbar-nav > .active > a:focus {
	color: @darker-text-gray;
}
.header-socials li a:hover {
  color: @darker-text-gray;
}

/* Sliders
------------------------------------*/
/* Main Parallax Sldier */
.da-slide h2 i {
	background-color: rgba(71, 101, 160, 0.8);
}

/* Sequence Parallax Sldier */
.sequence-inner {
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#cad9f6));
  background: -webkit-linear-gradient(#fff, #cad9f6);
  background: -moz-linear-gradient(#fff, #cad9f6);
  background: -ms-linear-gradient(#fff, #cad9f6);
  background: -o-linear-gradient(#fff, #cad9f6);
  background: linear-gradient(#fff, #cad9f6)
}
#sequence-theme h2 {
	background: rgba(71, 101, 160, 0.8);
}
#sequence-theme .info p {
	background: rgba(71, 101, 160, 0.8);
}

/* Buttons
------------------------------------*/
.btn-u {
	background: @darker-text-gray;
}
.btn-u:hover, 
.btn-u:focus, 
.btn-u:active, 
.btn-u.active, 
.open .dropdown-toggle.btn-u {
	background: @text-gray;
	color: #fff;
}

/* Buttons Color */
.btn-u-split.dropdown-toggle {
   border-left: solid 1px @text-gray;
}

/* Bordered Buttons */
.btn-u.btn-brd {
  border-color: @darker-text-gray;
}
.btn-u.btn-brd:hover {
  color: @text-gray;
  border-color: @text-gray;
}
.btn-u.btn-brd.btn-brd-hover:hover {
  background: @text-gray;
}

/* Service
------------------------------------*/
.service .service-icon {
	color: @darker-text-gray;
}

/* Service Blocks */
.service-alternative .service:hover { 
	background: @darker-text-gray;
} 

/* Thumbnail (Recent Work)
------------------------------------*/
.thumbnail-style h3 a:hover {
	color: @darker-text-gray;
}
.thumbnail-style a.btn-more {
	background: @darker-text-gray;
}
.thumbnail-style a.btn-more:hover {
	box-shadow:0 0 0 2px @text-gray;
}

/* Typography
------------------------------------*/
/* Heading */
.headline h2, 
.headline h3, 
.headline h4 {
	border-bottom:2px solid @darker-text-gray;
}

/* Blockquote */
blockquote:hover {
	border-left-color: @darker-text-gray;
}
.hero {
	border-left-color: @darker-text-gray;
}
blockquote.hero.hero-default {
  background: @darker-text-gray;
}
blockquote.hero.hero-default:hover {
  background: @text-gray;
}

/* Carousel
------------------------------------*/
.carousel-arrow a.carousel-control:hover {
	color: @darker-text-gray;
}

/* Footer
------------------------------------*/
.footer a,
.copyright a,
.footer a:hover,
.copyright a:hover {
	color: @darker-text-gray;
}

/* Footer Blog */
.footer .dl-horizontal a:hover {
	color: @darker-text-gray !important;
}

/* Blog Posts
------------------------------------*/
.posts .dl-horizontal a:hover { 
	color: @darker-text-gray;
}
.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a { 
	color: @darker-text-gray;
	border-color: @darker-text-gray !important;
}

/* Post Comment */
.post-comment h3, 
.blog-item .media h3,
.blog-item .media h4.media-heading span a {
	color: @darker-text-gray;
}

/* Tabs
------------------------------------*/
/* Tabs v1 */
.tab-v1 .nav-tabs { 
	border-bottom: solid 2px @darker-text-gray;
}
.tab-v1 .nav-tabs > .active > a, 
.tab-v1 .nav-tabs > .active > a:hover, 
.tab-v1 .nav-tabs > .active > a:focus { 
	background: @darker-text-gray;
}
.tab-v1 .nav-tabs > li > a:hover { 
	background: @darker-text-gray;
}

/* Tabs v2 */
.tab-v2 .nav-tabs li.active a {
	border-top: solid 2px @darker-text-gray;
}

/* Tabs v3 */
.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
	background: @darker-text-gray;
	border: solid 1px @text-gray;
}

/* Accardion
------------------------------------*/
.acc-home a.active,
.acc-home a.accordion-toggle:hover { 
	color: @darker-text-gray;
}
.acc-home .collapse.in { 
	border-bottom:solid 1px @darker-text-gray;
}

/* Testimonials
------------------------------------*/
.testimonials .testimonial-info {
	color: @darker-text-gray;
}
.testimonials .carousel-arrow i:hover {
	background: @darker-text-gray;
}

/* Info Blocks
------------------------------------*/
.info-blocks:hover i.icon-info-blocks {
	color: @darker-text-gray;
}

/* Breadcrumb
------------------------------------*/
.breadcrumb li.active,
.breadcrumb li a:hover {
	color: @darker-text-gray;
}

/* About Page
------------------------------------*/
.team .thumbnail-style:hover h3 a {
	color: @darker-text-gray !important;
}

/* Social Icons */
.team ul.team-socail li i:hover {
	background: @darker-text-gray;
}

/* Right Sidebar
------------------------------------*/
/* Right Sidebar */
.who li i,
.who li:hover i, 
.who li:hover a { 
	color: @darker-text-gray;
}

/* Privacy Page
------------------------------------*/
.privacy a:hover {
	color: @darker-text-gray;
}

/* Portfolio Page
------------------------------------*/
/* Portfolio v1 */
.view a.info:hover {
	background: @darker-text-gray;
}

/* Portfolio v2 */
.sorting-block .sorting-nav li.active {
	color: @darker-text-gray;
	border-bottom: solid 1px @darker-text-gray;
}
.sorting-block .sorting-grid li a:hover span.sorting-cover {
	background: @darker-text-gray;
}

/* Blog Page
------------------------------------*/
.blog h3 {
	color: @darker-text-gray;
}
.blog li a:hover {
	color: @darker-text-gray;
}

/* Blog Tags */
ul.blog-tags a:hover {
	background: @darker-text-gray;
}
.blog-post-tags ul.blog-tags a:hover {
  background: @darker-text-gray;
}

/* Blog Photos */
.blog-photos li img:hover {
	box-shadow: 0 0 0 2px @darker-text-gray;
}

/* Blog Latest Tweets */
.blog-twitter .blog-twitter-inner:hover {
	border-color: @darker-text-gray;
	border-top-color: @darker-text-gray;
}
.blog-twitter .blog-twitter-inner:hover:after {
	border-top-color: @darker-text-gray;
}
.blog-twitter .blog-twitter-inner a {
	color: @darker-text-gray;
}

/* Blog Item Page
------------------------------------*/
.blog-item h4.media-heading span a {
	color: @darker-text-gray;
}

/* Coming Soon Page
------------------------------------*/
.coming-soon-border {
	border-top: solid 3px @darker-text-gray;
}

/* Search Page
------------------------------------*/
.booking-blocks p a {
	color: @darker-text-gray;
}

/* Icons Page
------------------------------------*/
.icon-page li:hover { 
	color: @darker-text-gray;
}

/* Glyphicons */
.glyphicons-demo a:hover {
	color: @darker-text-gray;
	text-decoration: none;
}

/* Social Icons
------------------------------------*/
.social-icons-v1 i:hover {
  color: #fff;
  background: @darker-text-gray;
}

/* Magazine Page
------------------------------------*/
/* Magazine News */
.magazine-news .by-author strong {
	color: @darker-text-gray;
}

.magazine-news a.read-more {
	color: @darker-text-gray;
}

/* Magazine Mini News */
.magazine-mini-news .post-author strong {
	color: @darker-text-gray;
}
.news-read-more i {
	background: @darker-text-gray;
}

/* Sidebar Features */
.magazine-page h3 a:hover {
	color: @darker-text-gray;
}

/* Page Features
------------------------------------*/
/* Tag Boxes v1 */
.tag-box-v1 {
	border-top: solid 2px @darker-text-gray;
}

/* Tag Boxes v2 */
.tag-box-v2 {
	border-left: solid 2px @darker-text-gray;
}

/* Tag Boxes v7 */
.tag-box-v7 {
	border-bottom: solid 2px @darker-text-gray;
}

/* Font Awesome Icon Page Style */
.fa-icons li:hover { 
  color: @darker-text-gray;
}
.fa-icons li:hover i {
  background: @darker-text-gray;
}

/* GLYPHICONS Icons Page Style */
.bs-glyphicons li:hover {
  color: @darker-text-gray;
}

/* Navigation
------------------------------------*/
/* Pagination */
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
  background-color: @darker-text-gray;
  border-color: @darker-text-gray;
}
.pagination li a:hover {
  background: @text-gray;
  border-color: @text-gray;
}

/* Pager */
.pager li > a:hover, 
.pager li > a:focus {
  background: @text-gray;
  border-color: @text-gray;
}
.pager.pager-v2 li > a:hover, 
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover, 
.pager.pager-v3 li > a:focus {
  color: #fff;
  background: @darker-text-gray;
}

/* Registration and Login Page v2
------------------------------------*/
.reg-block {
	border-top: solid 2px @darker-text-gray;
}

/*Image Hover
------------------------------------*/
/* Image-hover */
#effect-2 figure .img-hover {
	background: @darker-text-gray;
}

/* Blog Large Page
------------------------------------*/
.blog h2 a:hover {
	color: @darker-text-gray;
}

/* Timeline v1 Page
------------------------------------*/
.timeline-v1 > li > .timeline-badge i:hover {
	color: @darker-text-gray;
}
.timeline-v1 .timeline-footer .likes:hover i {
	color: @darker-text-gray;
}

/* Timeline v2 Page
------------------------------------*/
/* The icons */
.timeline-v2 > li .cbp_tmicon {
	background: @darker-text-gray;
}

/* Progress Bar
------------------------------------*/
.progress-bar-u {
  background: @darker-text-gray;
}

/* Job Inner Page
------------------------------------*/
.job-description .save-job a:hover,
.block-description .save-job a:hover {
	color: @darker-text-gray;
}

.job-description .p-chart .overflow-h li i,
.job-description .p-chart .overflow-h li a,
.block-description .p-chart .overflow-h li i,
.block-description .p-chart .overflow-h li a {
	color: @darker-text-gray;
}

/* Colorful-ul */
.job-description .colorful-ul li a {
	color: @darker-text-gray;
}

/* Search Inner Page
------------------------------------*/
.s-results .related-search a:hover {
	color: @darker-text-gray;
}
.s-results .inner-results h3 a:hover {
	color: @darker-text-gray;
}
.s-results .up-ul li a:hover {
	color: @darker-text-gray;
}
.s-results .down-ul li a {
	color: @darker-text-gray;
}

/* Funny Boxes
------------------------------------*/
.funny-boxes p a {
  color: @darker-text-gray;
}
.funny-boxes .funny-boxes-img li i {
  color: @darker-text-gray;
}
.funny-boxes-colored p, .funny-boxes-colored h2 a, .funny-boxes-colored .funny-boxes-img li, .funny-boxes-colored .funny-boxes-img li i {
  color: #fff;
}

/* Sidebar Sub Navigation
------------------------------------*/
.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
  color: @darker-text-gray;
}

/* Blockquote
------------------------------------*/
blockquote.bq-green {
  border-color: @darker-text-gray;
}
blockquote:hover,
blockquote.text-right:hover {
  border-color: @darker-text-gray;
}
.quote-v1 p::before {
  color: @darker-text-gray;
}

/* Green Left Bordered Funny Box */
.funny-boxes-left-green {
  border-left: solid 2px @darker-text-gray;
}
.funny-boxes-left-green:hover {
  border-left-color: @darker-text-gray;
}

/* Testimonials Default
------------------------------------*/
/* Testimonials */
.testimonials .carousel-arrow i:hover {
  background: @darker-text-gray;
}

/* Testimonials Default */
.testimonials-bg-default .item p {
  background: @darker-text-gray;
}
.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: @darker-text-gray;
}
.testimonials-bg-default .carousel-arrow i {
  background: @darker-text-gray;
}
.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: @text-gray;
}

/* Promo Page
------------------------------------*/
/* Promo Box */
.promo-box:hover strong, 
.promo-box:hover strong a {
	color: @darker-text-gray;
}

/* Typography
------------------------------------*/
.dropcap {
	color: @darker-text-gray;
}

.dropcap-bg {
	color: #fff;
	background: @darker-text-gray;
}

/* Breadcrumbs
------------------------------------*/ 
span.label-u,
span.badge-u {
  background: @darker-text-gray;
}

/* Icons
------------------------------------*/
/* Icon Link*/
.link-icon:hover i {
  color: @darker-text-gray;
  border: solid 1px @darker-text-gray;
}

.link-bg-icon:hover i {
  color: @darker-text-gray;
  background: @darker-text-gray !important;
  border-color: @darker-text-gray;
}

/* Icons Backgroun Color
------------------------------------*/ 
i.icon-color-u {
  color: @darker-text-gray;
  border: solid 1px @darker-text-gray;
}
i.icon-bg-u {
  background: @darker-text-gray;
}

/* Line Icon Page
------------------------------------*/
.line-icon-page .item:hover {
	color: @darker-text-gray;
}

/* Colored Content Boxes
------------------------------------*/
.service-block-u {
  background: @darker-text-gray;
}

/* Panels (Portlets)
------------------------------------*/
.panel-u {
	border-color: @darker-text-gray;
}
.panel-u > .panel-heading {
   background: @darker-text-gray;
}

/* Owl Carousel
------------------------------------*/
.owl-btn:hover {
  background: @darker-text-gray;
}

/* Counter
------------------------------------*/
.counters span.counter-icon i {
	background: @darker-text-gray;
}
.counters span.counter-icon i:after {
	border-top: 7px solid @darker-text-gray;
}

/* SKy-Forms
------------------------------------*/
/* Buttons */
.sky-form .button {
	background: @darker-text-gray;
}

/* Rating */
.sky-form .rating input:checked ~ label {
	color: @darker-text-gray;
}

/* Message */
.sky-form .message {
	color: @darker-text-gray;
}
.sky-form .message i {
	border-color: @darker-text-gray;
}

/* Profile
------------------------------------*/
.profile .profile-post:hover span.profile-post-numb {
	color: @darker-text-gray;
}
.profile .date-formats {
	background: @darker-text-gray;
}
.profile .name-location span i,
.profile .name-location span a:hover {
	color: @darker-text-gray;
}
.share-list li i {
	color: @darker-text-gray;
}
.profile .comment-list-v2 li:hover i,
.profile .comment-list li:hover i {
	color: @darker-text-gray;
}
.profile .profile-post.color-one {
	border-color: @darker-text-gray;
}

/* Pricing Page
------------------------------------*/
/* Pricing Head */
.pricing:hover h4 {
	color: @darker-text-gray;
}
.pricing-head h3 {
	background: @darker-text-gray;
	text-shadow: 0 1px 0 @text-gray;
}
.pricing-head h4 {
	color:#999;
	background:#fcfcfc;
	border-bottom:solid 1px #cad9f6;
}
	
/* Pricing Content */
.pricing-content li {
	border-bottom:solid 1px #cad9f6;
}
.pricing-content li i {
	color: @darker-text-gray;
}

/* Pricing Extra */
.sticker-left {
	background: @darker-text-gray;
}

/* Pricing Footer */
.pricing-footer a:hover,
.pricing-footer button:hover {
	background: @text-gray;
}

/* Pricing Active */
.price-active h4 {
	color: @darker-text-gray;
}
.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
	color: @darker-text-gray;
}

/* Mega Pricing Tables 
------------------------------------*/
.pricing-mega-v1 .pricing-head h3,
.pricing-mega-v2 .pricing-head h3,
.pricing-mega-v3 .pricing-head h3 {
	text-shadow: 0 1px 0 @text-gray;
}

/* Pricing Table Mega v1 Version
------------------------------------*/
.pricing-mega-v1 .pricing:hover h4 i {
	color: @darker-text-gray;
}
.pricing-mega-v1 .pricing-content li i {
	color: @darker-text-gray;
}

/* Pricing Table Colored Background Version
------------------------------------*/
.pricing-bg-colored .pricing:hover {	
	background: @darker-text-gray;
}
.pricing-bg-colored .pricing-head i {
	color: @darker-text-gray;
}
.pricing-bg-colored .pricing-footer .btn-u {
	border: 1px solid #fff;
}
.pricing-bg-colored .pricing-head p {
  border-bottom: 1px solid #cad9f6;
}

/* Pricing Table Mega v2 
------------------------------------*/
.pricing-mega-v2 .block:hover .bg-color {
	background: @darker-text-gray;
}
.pricing-mega-v2 .block:hover h3,
.pricing-mega-v2 .block:hover h4, 
.pricing-mega-v2 .block:hover li, 
.pricing-mega-v2 .block:hover li i,
.pricing-mega-v2 .block:hover h4 i {
	background: @darker-text-gray;
}

/* Pricing Table Mega v3 
------------------------------------*/
.pricing-mega-v1 .btn-group .dropdown-menu,
.pricing-mega-v3 .btn-group .dropdown-menu {
	background: @darker-text-gray !important;
}

.pricing-mega-v1 .btn-group .dropdown-menu li a:hover,
.pricing-mega-v3 .btn-group .dropdown-menu li a:hover {
	background: @text-gray;
}

/* Grid Block v2 
------------------------------------*/
.grid-block-v2 li:hover .grid-block-v2-info {
  border-color: @darker-text-gray;
}

/* Testimonials v3 Title 
------------------------------------*/
.testimonials-v3 .testimonials-v3-title p {
  color: @darker-text-gray;
}

.testimonials-v3 .owl-buttons .owl-prev:hover,
.testimonials-v3 .owl-buttons .owl-next:hover {
  background: @darker-text-gray;
}

/* Content Boxes v4 
------------------------------------*/
.content-boxes-v4 i {
  color: @darker-text-gray;
}

/* Thumbnails v1 
------------------------------------*/
.thumbnails-v1 .read-more {
  color: @darker-text-gray;
}

/* Thumbnails v6 
------------------------------------*/
.testimonials-v6 .testimonials-info:hover {
  border-color: @darker-text-gray;
}

/* Team v1 
------------------------------------*/
.team-v1 li:hover > p:before {
  background: @darker-text-gray;
}

/* Team v4
------------------------------------*/
.team-v4 .team-social-v4 a:hover {
  color: @darker-text-gray;
}

/* Team v5 & v6 & v7
------------------------------------*/
.team-v5 small,
.team-v6 small,
.team-v7 .team-v7-position {
  color: @darker-text-gray;
}

/* Headliner Center
------------------------------------*/
.headline-center h2:after {
  background: @darker-text-gray;
}

/* Headliner Left
------------------------------------*/
.headline-left .headline-brd:after {
  background: @darker-text-gray;
}

/* Portfolio Box
------------------------------------*/
.portfolio-box .portfolio-box-in i {
  background: @darker-text-gray;
}

/* Flat Background Block v1
------------------------------------*/
.flat-bg-block-v1 .checked-list i {
  color: @darker-text-gray;
}

/* Owl Carousel v5
------------------------------------*/
.owl-carousel-v5 .owl-controls .owl-page.active span,
.owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: @darker-text-gray;
}

/* Content Boxes v5
------------------------------------*/
.content-boxes-v5:hover i {
  background: @darker-text-gray;
}

/* Block Grid v1
------------------------------------*/
.block-grid-v1:hover {
  border-color: @darker-text-gray;
}

/* Block Grid v2
------------------------------------*/
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: @darker-text-gray;
}

/* Content Boxes v6
------------------------------------*/
.content-boxes-v6:hover i:after {
  border-color: @darker-text-gray;
}
.content-boxes-v6:hover i {
  background: @darker-text-gray;
}

/* Portfolio Box-v2
------------------------------------*/
.portfolio-box-v2 .portfolio-box-v2-in i {
  background:  rgba(71, 101, 160, 0.8);
}
.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: @darker-text-gray;
}

/* Service Block v1
------------------------------------*/
.service-block-v1 i {
  background: @darker-text-gray;
}

/* Service Block v4
------------------------------------*/
.service-block-v4 .service-desc i {
  color: @darker-text-gray;
}

/* Service Block v7
------------------------------------*/
.service-block-v7 i {
  background: @darker-text-gray;
}

/* Service Block v8
------------------------------------*/
.service-block-v8 .service-block-desc h3::after {
  background: @darker-text-gray;
}

/* Testimonials bs
------------------------------------*/
.testimonials-bs .carousel-control-v2 i:hover {
  border-color: @darker-text-gray;
  color: @darker-text-gray;
}

/* Fusion Portfolio
------------------------------------*/
.fusion-portfolio #filters-container .cbp-filter-item-active {
  background: @darker-text-gray;
  border-color: @darker-text-gray;
}

 .fusion-portfolio #filters-container .cbp-filter-item:hover {
  color: @darker-text-gray;
}

.blog_masonry_3col h3 a:hover {
  color: @darker-text-gray;
}

/* Cube Portfolio
------------------------------------*/
.cube-portfolio .cbp-l-filters-text .cbp-filter-item.cbp-filter-item-active, .cube-portfolio .cbp-l-filters-text .cbp-filter-item:hover {
  color: @darker-text-gray;
}
.cube-portfolio .link-captions li i:hover {
	color: #fff;
	background: @darker-text-gray;
}
.cube-portfolio .cbp-caption-activeWrap.default-transparent-hover {
  background: rgba(71, 101, 160, .9) !important;
}

/* Recent Works
------------------------------------*/
.owl-work-v1 .item a:hover span {
  border-bottom-color: @darker-text-gray;
}

/* Footer Default
------------------------------------*/
.footer-default .footer .dl-horizontal a:hover {
  color: @darker-text-gray !important;
}
.footer-default .footer a {
    color: @darker-text-gray;
}
.footer-default .footer a:hover {
  color: @text-gray;
}
.footer-default .copyright a {
  color: @darker-text-gray;
}
.footer-default .copyright a:hover {
  color: @text-gray;
}

/* Footer v4
------------------------------------*/
.footer-v4 .copyright a {
  color: @darker-text-gray;
}

/* Title v1
------------------------------------*/
.title-v1 h1:after, .title-v1 h2:after {
  background-color: @darker-text-gray;
}

/* Copyright Section
------------------------------------*/
.copyright-section i.back-to-top:hover {
  color: @darker-text-gray;
}

/* Top Control
------------------------------------*/
#topcontrol:hover {
  background-color: @darker-text-gray;
}

/* News Info
------------------------------------*/
.news-v1 .news-v1-info li a:hover {
  color: @darker-text-gray;
}
.news-v1 h3 a:hover {
    color: @darker-text-gray;
}
.news-v2 .news-v2-desc h3 a:hover {
    color: @darker-text-gray !important;
}
.news-v3 .post-shares li span {
  background: @darker-text-gray;
}
.news-v3 .posted-info li a:hover {
  color: @darker-text-gray !important;
}
.news-v3 h2 a:hover {
  color: @darker-text-gray !important;
}

/* Blog Trending
------------------------------------*/
.blog-trending small a:hover {
  color: @darker-text-gray;
}

/* Blog Masonry
------------------------------------*/
.blog_masonry_3col ul.grid-boxes-news li a:hover {
	color: @darker-text-gray;
}

/* List v1
------------------------------------*/
.lists-v1 i {
  background: @darker-text-gray;
}

/* List v2
------------------------------------*/
.lists-v2 i {
  color: @darker-text-gray;
}

/* Process v1
------------------------------------*/
.process-v1 .process-in > li i {
  background: @darker-text-gray;
}

/* Featured Blog
------------------------------------*/
.featured-blog h2::after {
  background: @darker-text-gray;
}
.featured-blog .featured-img i:hover {
  color: #fff;
  background: @darker-text-gray;
}

.rgba-default {
  background-color: rgba(71, 101, 160, 1);
}

/* Blog Latest Posts
------------------------------------*/
.blog-latest-posts h3 a:hover {
	color: @darker-text-gray !important;
}

/* Blog Trending
------------------------------------*/
.blog-trending h3 a:hover {
	color: @darker-text-gray !important;
}
