.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
h1.blue,
h2.blue,
h3.blue,
h4.blue,
h5.blue {
  color: #002446 !important;
}
h1.grey,
h2.grey,
h3.grey,
h4.grey,
h5.grey {
  color: #868686 !important;
}
h1.yellow,
h2.yellow,
h3.yellow,
h4.yellow,
h5.yellow {
  color: #fac700 !important;
}
h1.dark-grey,
h2.dark-grey,
h3.dark-grey,
h4.dark-grey,
h5.dark-grey {
  color: #565656 !important;
}
h1.green,
h2.green,
h3.green,
h4.green,
h5.green {
  color: #95d148 !important;
}
h1.white,
h2.white,
h3.white,
h4.white,
h5.white {
  color: #FFFFFF;
}
h1 {
  font-size: 28px;
  font-weight: bold;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 21px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
.h1 {
  font-size: 38px !important;
}
.h2 {
  font-size: 32px !important;
}
.h3 {
  font-size: 28px !important;
}
.h4 {
  font-size: 24px !important;
}
.h5 {
  font-size: 18px !important;
}
.slim {
  font-weight: 300 !important;
}
.medium {
  font-weight: 400 !important;
}
.bold {
  font-weight: 600 !important;
}
.text-blue {
  color: #324c80 !important;
}
.text-deep-blue {
  color: #002446 !important;
}
.text-deep-blue a {
  color: #002446;
}
