@import "../_variables.less";

// JobOffer

.recommend {
  #downloadButton {
    display: none !important;
    visibility: hidden !important;
  }
}

// JobOffer Apply -> needs according js
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.jobOffer {

  #yVideo {
    padding-right: 0px;
  }

  #gMaps.remove-padding{
    padding-left: 0px;
  }

  .iconSet ul.dropdown-menu {
    li {
      margin-left: 10px;
      margin-right: 10px;
      border-bottom: 1px solid @light-grey;

      .btn-icon {
        font-size: 16px;
      }

      button.btn-icon {
        padding: 3px 10px;
      }

      span {
        color: @darkest-blue;
      }

      .fa {
        width: 15px;
        margin-right: 10px;
        color: @darkest-blue;
      }
    }
    li:empty {
      border: none;
    }
    li:last-child {
      border: none;
    }
  }
}

.prevNextNav {


  > div {
    padding: 0;
  }
  font-size: 15px;
  margin:20px 0 0;
  a {
    color:@text-gray;
    text-decoration: none !important;
    font-weight: 600;
    font-size: 13px;
    i {
      margin:0 2px 0;
      color:@ism-yellow;
    }
    &:hover {
      color: @darker-text-gray;
      i {
        color: @darker-text-gray;
      }
    }
  }
}


.jobOffer, .publicationView {

  #applyButton, #contactButton {
    margin-top: 75px
  }

  hr {
    border-color: #fff;
    &.hr-md {
      margin: 14px 0 25px;
    }
  }


  .square-content {
    background: #fff;
  }
  #jobOfferTitle, #publicationOfferTitle {
    margin:0;
    font-size: 24px;
    color:@darker-blue;
  }

  #companyName, #personName {
    font-size:18px;
    color: @darker-blue;
  }

  #companyName a {
    font-weight: 600;
    color:@darker-blue;
    font-size: 18px
  }

  .offerData {
    color:@darker-blue;
    font-weight: 400;
  }

  .jobMeta, .personalMeta, .personalContact {
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .dataAndAction {
    margin-top: 57px;
  }
}

.tab-v1 .nav-tabs {
  border-bottom-width: 0 !important;
  > li {
    a {
      background: white;
      font-size: 18px;
      padding:10px 20px 7px;
      margin-right: 0;
    }
    &.active a {
      color: @darker-text-gray;
      background: white;
      &:hover,
      &:focus {
        background: white;
        color:@footer-color;
      }
      &:focus {
        color:@darker-text-gray;
      }
    }
    > a {
      &:hover,
      &:focus {
        background: white;
        color: @darker-text-gray;
      }
    }
  }
}

.pdf-controlBar {
  color: @darkest-blue;
  padding:0 2px;
  font-size: 13px;

  a {
    font-weight: 600;
    color: @text-gray;
    text-decoration: none !important;
    i {
      padding:0 5px;
      color:@ism-yellow;
    }
    &:hover {
      color:@darker-text-gray;
      i {
        color:@darker-text-gray;
      }
    }
  }
}

#employerFooter {
  margin:30px 0;
  font-weight: @slim;
  #contactPanel {
    h5#contactPersonHead,
    h5#companyName {
      font-size: 22px;
      font-weight: 600;
      color:@text-gray;
      margin-top: 3px;
    }
  }

  .contactBold {
    font-weight: 600;
  }

  .contactDetails {
    line-height: 1.7;
    table tr td {
      border-right: 5px solid transparent;
    }
  }

  .contactRowType {
    vertical-align: top;
  }

  .contactMail {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width : 1200px) {
    .padding-logo {
      padding-left: 0px;
    }
    .padding-contactPerson {
      padding-right: 0px;
      padding-left: 80px;
    }
  }

  .box-row-top {
    padding-top: 20px;
  }
  .box-row {
    padding-left: 20px;
    padding-right: 20px;
  }
  #recruitersWelcome {
    color: @error-red-light;
    font-size: larger;
  }


  .company-box {
    width: 120px + @square-box-padding;
    height: 100px + @square-box-padding;
    .company-box-content {
      span img {
        max-width: 120px;
        max-height: 100px;
      }
    }
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: 480px) {
    .company-box {
      width: 160px + @square-box-padding;
      height: 140px + @square-box-padding;
      .company-box-content {
        span img {
          max-width: 160px;
          max-height: 140px;
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width : 768px) {
    .company-box {
      width: 180px + @square-box-padding;
      height: 140px + @square-box-padding;
      .company-box-content {
        span img {
          max-width: 180px;
          max-height: 140px;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width : 992px) {

    .footerCompanyBlock {
      // ugly hack to fix #1585 and align company names to infopanel company names
      position: relative;
      left: -32%;
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width : 1200px) {
    .footerCompanyBlock {
      // ugly hack to fix #1585 and align company names to infopanel company names
      position: relative;
      left: -18%;
    }
  }

  .contact-person-logo {
    max-width: 100%;
    max-height: 140px + @square-box-padding;
  }
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  #contactPanel>#companyData {
    //border-right: 1px solid @blue;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

#profileTab, #jobSearchTab, #matchingResultTab {

  font-weight: 300;
  hr {
    border-color: @light-blue;
  }
  #profileGroups, #resultProfileGroups {
    h3 {
      margin-top: 0;
    }
  }
  h3 {
    font-size:24px;
    color: @darker-blue;
    font-weight: 300;
  }

}

#advertisementTab {
  #headertext {
    margin-top: 0;
  }
}

#responsibilityTab, #profileTab #personalTab, #profileTab #employmentTab, #generalTab, #professionalTab, #employmentTab, #accordionSearched  {
  h4 {
    font-weight: 400;
    font-size: 16px;
    border-bottom:3px solid @darker-text-gray;
    padding-bottom: 4px;
    margin-bottom: 5px;
    color: @darker-text-gray;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    color: @darker-text-gray;
  }

  div {
    color: @darker-text-gray;
  }
}

@media print {
  #profileTab, #jobSearchTab, #matchingResultTab {
    h3 {
      font-size: 21px !important;
    }
  }
}
