@import "../_variables.less";
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);

/* common */

html,
body {
  height: 100%;
}

body {
  .PetagFont();

  /* we need padding-top for the fixed navbar*/
  padding-top: 185px;

  @media (min-width: 768px) {
    padding-top: 132px;
  }

  @media (min-width: 1024px) {
    padding-top: 156px;
  }
}

.bold {
  font-family: @bold-font-family;

}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.light {
  font-family: @light-font-family;
}

.text-gray {
  color: @text-gray;
}

.page-row {
  display: table-row;
  height: 1px;
}

.page-row-expanded {
  height: 100%;
}

.btn-yellow {
  color: #ffffff !important;
  background-color: @ism-yellow !important;
  border-color: @ism-yellow !important;
  &.btn {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.btn-green {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  background-color: #76b631 !important;
  border-color: #76b631 !important;
  &.btn {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.btn-green:hover {
  color: #868686 !important;
}

.btn-upload {
  margin-right: 5px;
}

a {
  color: @darker-text-gray;
}

.btn-yellow.text-blue:hover {
  color: #fff !important;
}

// fixes gap from round buttons when dropdown is open
.open.btn-group .multiselect {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.multiselect-container {
  border-radius: 0 0 @border-radius-default @border-radius-default;
}

button.multiselect {

  .caret {
    border: none !important;
    vertical-align: top;
    font-size: 13px;
    padding-top: 2px;
    position: absolute;
    right: 25px;

    &:before {
      font-family: FontAwesome;
      content: "\f0dc";
      margin-left: 5px;
    }
  }
}

.job-img-inputs-bg {
  background-color: @darkest-blue;
  padding: 8px 14px;
  border-radius: 5px !important;

  .btn-yellow {
    padding: 4px 12px 3px;
  }

  .row > div {
    margin: 7px 0 !important;
  }

  .row {
    margin: 15px 0;
  }

  .open button.multiselect {
    background: @power-blue;
  }
  .blue-multiselect .dropdown-menu > li > a {
    padding-left: 0;
  }
}


.blue-select {
  background-color: @power-blue;
  color: #fff;

  //.caret {
  //  color: @ism-yellow;
  //}
}

.btn-round, .btn-round button {
  border-radius: 3px !important;
}

.generate-margin-top(10);

.generate-margin-top(@n, @i:1) when (@i <= @n) {
  @value: (@i*10);
  .margin-top-@{value} {
    margin-top: (@value*1px);
  }
  .generate-margin-top(@n, (@i + 1));
}

.generate-margin(10);

.generate-margin(@n, @i:1) when (@i <= @n) {
  @value: (@i*10);
  .margin-@{value} {
    margin-bottom: (@value*1px);
    margin-top: (@value*1px);
  }
  .generate-margin(@n, (@i + 1));
}

.padding-0 {
  .padding-left-0;
  .padding-right-0;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-right-0 {
  padding-right: 0px;
}

.background-white {
  background-color: white;
}

.background-light-green {
  background-color: @section-light-green;
}

.background-dark-green {
  background-color: @section-dark-green;
}

.dark-blue-block {
  background-color: @darkest-blue;
  color: white;
  padding: 10px;
}

.indexActive > a {
  color: @ism-yellow;
}

.radio-inline-label {
  margin-right: 20px;
  margin-top: 4px;
}

// OfferTable
.offerTableJobTitle {
  font-size: 110%;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.popover {
  color: @darkest-blue;
}

.iconSet {
  .btn-icon {
    padding-top: 0;
    background: none;
    font-size: 22px;
    border: none;
    color: @text-gray;

    &:hover {
      color: @ism-yellow;
    }
  }
}

button, .btn-group,
.btn, .btn-round {
  border-radius: 3px;
}

// prevents Chrome from yellowizing the input fields
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

//fix Icons
.icon {
  transform: scale(1.5);
  overflow: hidden;

}

.row-eq-height,.vertical-align {
  display: block;
}

@media only screen and ( min-width: 768px) {
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }

  .vertical-align {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

// Messages from the Server
.message-box ul li {
  list-style: none;
}

.btn-blue {
  background: @darkest-blue;
  border-color: @darker-blue;
  color: #fff;
  margin: 4px 7px 4px 0;
}

.btn-blue:hover,
.btn-blue:focus {
  color: @ism-yellow;
}

.btn {
  font-size: 18px;
}

.btn-sm {
  font-size: 15px;
  line-height: 1.25;
  &.btn-yellow {
  }
}

.btn-xs {
  font-size: 14px;
  line-height: 1.25;
  &.btn-yellow {
  }
}

.btn-delete {
  color: @error-red-light;
  text-decoration: none !important;
  &:hover,
  &:focus {
    color: @error-red-dark;
  }
}

.btn-add {
  background: #fff;
  color: @ism-yellow !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 32px;
  height: 30px;
  margin: 0 8px;
  padding: 6px 15px;
  position: relative;
  line-height: 15px;

  &:before {
    content: "+";
    position: absolute;
    left: 0;
    right: 0;
  }
}

.btn-add-blue {
  .btn-add;
  background-color: @darkest-blue;
  line-height: 13px;

  &:hover,
  &:focus {
    color: white;
  }
}

.btn-lg {
  font-weight: @medium;
}

.rectangle {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;;
  border-radius: 0 !important;;
}

/**
  Tab as static navigation:
*/

.tab-v1 {
  a {
    -webkit-border-radius: 0 !important;;
    -moz-border-radius: 0 !important;;
    border-radius: 0 !important;
  }
  ul.nav-tabs-static {
    li {
      text-align: center;
      a {
        background: rgba(255,255,255,0.3);
        border-radius: 0;
        border-right: 1px solid #fff;
      }
      &:last-child a {
        border-right: none;
      }
      &.active a:hover {
        text-decoration: none;
      }
      a:focus, a:hover {
        text-decoration: none;
        background: rgba(255,255,255,1);
      }
      &.active > a {
        background: rgba(255,255,255,1);
      }
    }
  }
}

.progress-bar.yellow {
  background: @ism-yellow;
}
//

/**
 maxWdiths
*/

.maxWidth-100 {
  max-width: 100px;
}

.maxWidth-200 {
  max-width: 200px;
}

.maxWidth-300 {
  max-width: 300px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .btn-round-left-gt-sm {
    border-radius: @border-radius-default 0 0 @border-radius-default !important;

  }

  .btn-round-right-gt-sm {
    border-radius: 0 @border-radius-default @border-radius-default 0 !important;

  }

  .btn-round-not-gt-sm {
    border-radius: 0 !important;
  }

  .gapLeft-gt-sm {
    padding-left: 15px !important;
  }


  .text-right-gt-sm {
    text-align: right;
  }

  .pull-right-gt-sm {
    float: right;
  }

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .btn-round-left-gt-md {
    border-radius: @border-radius-default 0 0 @border-radius-default !important;
  }

  .btn-round-right-gt-md {
    border-radius: 0 @border-radius-default @border-radius-default 0 !important;
  }

  .btn-round-not-gt-md {
    border-radius: 0 !important;
  }

  .btn-round-not {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .button-bar {
    > div:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.8);
    }
  }

  .tab-v1 {
    ul.nav-tabs-static {
      &.fullWidth-4 {
        li {
          width: 100/4%;
        }
      }
      &.fullWidth-5 {
        li {
          width: 100/5%;
        }
      }
      &.fullWidth-6 {
        li {
          width: 100/6%;
        }
      }
      &.fullWidth-7 {
        li {
          width: 100/8%;
        }
      }
      &.fullWidth-8 {
        li {
          width: 100/8%;
        }
      }
    }
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}

/* Remove top margin for inline-radios example: Salutation*/
.inline-group {
  .radio-inline.radio {
    margin-top: 0px;
  }
}

/* Blue page labels */
.pageLabel {
  color: @darker-blue;
}

.sky-form .input-file .button {
  font-family: 'Klavika-Regular', sans-serif !important;
  font-size: 18px !important;
}

hr.white{
  border-top-color: white;
}

hr.x2 {
  border-width: 3px;
}

hr.grey {
  border-top-color: @employer-multiselect-grey;
}
.owl-carousel {
  // fixes chrome issues
  // https://github.com/OwlFonk/OwlCarousel/issues/56
  z-index: 1;
}

.progress-opacity {
  opacity: 0.6;
}


.progress-xs {
  height: 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;;

}



b, strong {
  .font500;
}



.modal.delete {

  .btn-u#dialog-confirm {
    background-color: @error-red-dark;
    border-color: @error-red-dark;
  }
}

#ajax-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
  display: none;
  background-color: rgba(0, 0, 0, 0.3);

  .ajax-popup {
    margin: 50px auto;
    width: 64px;
    height: 64px;
    background-color: @light-grey;
    border-radius: 3px;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.red-text {
  color: @error-red-light;
}

.padding-bottom-40{
  padding-bottom: 40px;
}

.padding-bottom-80{
  padding-bottom: 80px;
}
.documentsPreviewWrapper {
  border: 1px solid @darkest-blue;
}

.betweener {
  position: relative;
  top: -25px;
}

.grid-cell-home {
  border: solid;
  border-width: 30px 30px 0 0;
  border-color: @home-blue;
  padding: 20px;

  h3 {
    margin-top: 0 !important;
  }
}

.video-js .vjs-big-play-button {
  font-size: 7em !important;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}

.videoPlayerError {
  padding-left: 30px;
}

ul.share{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 34px;
  left: -15px;
  display: none;
  padding: 18px 11px 10px;
  list-style: none;
  min-width: 300px;
  position: absolute;
  background: @darkest-blue;
}

.grid-info-block {
  background-color: white;
  border: 15px solid @light-blue;


  .info-block-text {
    color: @text-gray;
  }

  .info-right-block {
    padding-top: 15px;
  }
}

.label-text-box {
  label {
    font-weight: 400;
    display: block;
    color: @darker-text-gray;
    border-bottom: 3px solid @darker-text-gray;
  }
  & > div{
    margin-bottom: 15px;
  }

  div {
    font-size: 14px;
    font-weight: 300;
    color: @darker-text-gray;
  }

  p {
    color: @darker-text-gray;
  }
}

p,
li,
li a,
label {
  color: @text-gray;
}

.img-responsive{
  width: 100%;
}

@media (min-width: 768px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 992px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}

/**
https://gist.github.com/larrybotha/7881691
IE9, IE10, IE11 svg scale problem
**/

/* 1 */
.ie9 img[src*=".svg"] {
  width: 100%;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*=".svg"] {
    width: 100%;
  }
}

.list-style-type-square {
  list-style-type: square;
}
