@import "../_variables";
body .form-layout-2 {

  // big fields
  // works best over light-blue ground
  // radio/checkbox white
  // fields little bit bigger
  // and with slim inset shadow
  //used here: http://localhost:9000/login/signup/seeker, http://localhost:9000/login/signup/employer

  @form-layout-2-base-text: #999999;
  @form-layout-2-error-text: #b8312f;


  .form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: 0;
    height: 40px;
    -webkit-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
    box-shadow: inset 1px 1px 0px 1px rgba(0,0,0,0.1);
  }


  textarea {
    font-size:18px;

    padding: 12px 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color:#999999;
    border: 0;
    //padding: 12px 12px;
    -webkit-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
    box-shadow: inset 1px 1px 0px 1px rgba(0,0,0,0.1)
  }

  h4 {
    font-size: 24px;
    margin: 19px 0 18px;
  }


  .form-group {
    margin-bottom: 18px;
  }

    //activate to get white Checkboxes and Radio Buttons
  //.radio-inline i,
  //.checkbox i {
  //  background: #fff !important;
  //  border-color: #fff !important;
  //  -webkit-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
  //  -moz-box-shadow: inset 1px 1px 5px 0px rgba(0,0,0,0.1);
  //  box-shadow: inset 1px 1px 0px 1px rgba(0,0,0,0.1);
  //  border:0 !important;
  //
  //  &:after {
  //  top:/*tbd*/0px !important;
  //  }
  //}




  .warningLabel,.note-error {
    color:@error-red-light;
    padding: 0 0 0 2px;
  }
  .state-error,.has-error {
    input.form-control, select, &:focus {
      background: #fff !important;
    }

    i {
      background: inherit !important;
    }

    .radio, .has-error .checkbox,  .radio-inline {
      color: inherit;
    }
  }


  .state-disabled,.state-disabled:hover {
    cursor: not-allowed;
    color:#555555 !important;
    opacity: 1!important;
    i {
      border-color:#333 !important;
    }
  }



  // file input fix

  .uploadButton {
    font-size: 34px;
  }

  &.sky-form .state-error input {
    background: #fff !important;
  }


  &.sky-form .state-error label  {
    color: inherit!important;
  }
}