// !important styles to overwrite all

@import "../_variables";
h1,
h2,
h3,
h4,
h5 {
  &.blue {
    color: @darkest-blue !important;
  }

  &.grey {
    color: @text-gray !important;
  }

  &.yellow {
    color: @ism-yellow !important;
  }

  &.dark-grey {
    color: @darker-text-gray !important;
  }
  &.green {
    color: @petag-green !important;
  }
  &.white {
    color: #FFFFFF;
  }
}




// declaring default sizes
h1 {
  font-size:28px;
  font-weight: bold;
}

h2 {
  font-size:24px;
}

h3 {
  font-size:21px;
}

h4 {
  font-size:18px;
}

h5 {
  font-size:16px;
}




// styles with .h1 - .h5 classes to make (seo-)unimportant things really large

.h1 {
  font-size: 38px !important;
}
.h2 {
  font-size: 32px !important;
}
.h3 {
  font-size: 28px !important;
}
.h4 {
  font-size: 24px !important;
}
.h5 {
  font-size: 18px !important;
}


.slim {
  font-weight: @slim !important;
}
.medium {
  font-weight: @medium !important;
}
.bold {
  font-weight: @bold !important;
}

.text-blue {
  color: @darker-blue !important;
}

.text-deep-blue {
  color: @darkest-blue !important;

  a {
    color: @darkest-blue;

  }
}
