.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
/* Navbar */
.navbar {
  margin-bottom: 0;
  border: 0px;
  background-color: #fff;
}
.header {
  border-bottom: 0;
}
.header .navbar-nav {
  padding-top: 1rem;
}
.header .navbar-nav > li > a {
  padding: 0.7em 1em 0.45em;
}
.header .navbar-nav > li > a:hover,
.header .header .navbar-nav > .active > a {
  border-bottom-color: #95d148;
}
.header .navbar-nav > .dropdown > a:after {
  content: "";
}
.header li.dropdown:last-child > .dropdown-menu {
  left: auto;
  right: 0;
}
.navbar .navbar-header > .nav-ism-logo {
  margin-left: 12px;
  padding: 10px 0 0 0;
}
.navbar .navbar-header > .nav-ism-logo img {
  max-width: 190px;
}
.header .dropdown-menu {
  border-top: 0;
  margin-top: -1px !important;
  padding-top: 1px;
}
.header .navbar-toggle {
  margin: 6px 15px 8px 0;
}
/*topbar*/
.header .topbar {
  padding: 0;
}
.header .topbar a {
  cursor: pointer;
}
.header .topbar .switch {
  padding: 8px;
  background: #fff;
}
.header .topbar .switch span {
  color: #888888 !important;
}
.header .topbar .switch span.activeSelection {
  color: black !important;
}
.navbar.navbar-default.mega-menu {
  border: 0;
}
.header .navbar-brand {
  margin-top: -20px;
}
.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom: 4px solid #95d148;
  box-sizing: border-box;
}
.header .navbar-nav > li > a {
  border-bottom: 4px solid white;
  bottom: 0 !important;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-bottom-color: #95d148;
}
.nav .dropdown:hover > a {
  border-bottom: 4px solid #95d148;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background: #fff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background: #fff;
}
.header .navbar-nav > .active > a:hover {
  color: #777777;
}
.header .navbar-nav > .active > a:focus {
  text-decoration: underline;
}
.header .navbar-nav .dropdown-toggle .caret {
  margin-top: 10px;
}
#___gcse_0 > div > form > table > tbody > tr > td.gsc-search-button > button,
#___gcse_1 > div > div > form > table > tbody > tr > td.gsc-search-button > button {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  color: #ffffff !important;
  background-color: #76b631 !important;
  border-color: #76b631 !important;
}
#___gcse_0 > div > form > table > tbody > tr > td.gsc-search-button > button.btn,
#___gcse_1 > div > div > form > table > tbody > tr > td.gsc-search-button > button.btn {
  padding-top: 4px;
  padding-bottom: 4px;
}
#___gcse_0 > div > form > table > tbody > tr > td.gsc-search-button > button:hover,
#___gcse_1 > div > div > form > table > tbody > tr > td.gsc-search-button > button:hover {
  color: #868686 !important;
}
#gs_cb50 {
  top: 5px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .navbar {
    padding-bottom: 10px;
    border-bottom: 1px solid #b2c0c9;
  }
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .navbar .navbar-header > .nav-ism-logo {
    margin-left: 0;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    max-width: 200px;
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .navbar .navbar-header > .nav-ism-logo {
    margin-left: 0;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    max-width: 175px;
  }
  .header .navbar-nav > li > a {
    padding: 9px 15px 9px 15px;
  }
  .header .navbar-nav > li > a {
    padding-top: 45px !important;
    padding-bottom: 35px !important;
  }
  .header .navbar-brand {
    padding: 10px 0 !important;
    margin-top: 16px;
  }
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    background: #fff;
  }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .header .navbar-nav > li > a {
    font-size: 19px;
  }
}
