.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.light-grey {
  color: #dddddd !important;
}
.dark-grey {
  color: #565656 !important;
}
.darkest-blue {
  color: #002446 !important;
}
.white {
  color: white !important;
}
.text-break {
  word-break: break-word;
}
.sky-form {
  font-family: 'Klavika-Regular', sans-serif;
  color: #868686;
  line-height: 1.5;
  display: table;
  width: 100%;
  font-size: 16px;
  font-family: 'Klavika-Regular', sans-serif !important;
}
.sky-form .button {
  opacity: 1;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border: 2px solid #404040;
}
.sky-form .radio i,
.sky-form .checkbox i {
  border-color: #404040;
  border-width: 2px;
  background: none;
}
.sky-form .radio:hover i {
  border-color: #404040;
}
.sky-form .radio input + i:after,
.sky-form .radio input ~ i:after {
  top: 4px;
  left: 4px;
  background: #404040;
}
.sky-form .radio input:focus + i {
  border-color: #404040;
}
.sky-form .fileButton {
  background-color: #002446;
  color: white;
}
.sky-form .has-error.state-error input:focus {
  border-color: #d81e26;
}
.sky-form .radio input:checked + i:after,
.sky-form .checkbox input:checked + i:after,
.sky-form .radio input:checked ~ i:after,
.sky-form .checkbox input:checked ~ i:after {
  color: #333;
}
.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover,
.sky-form .radio:focus i,
.sky-form .checkbox:focus i,
.sky-form .toggle:focus i,
.sky-form .ui-slider-handle:focus {
  border-color: #333;
}
.sky-form .checkbox input:focus ~ i,
.sky-form input:focus {
  box-shadow: 0 0 5px #195184;
}
